<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem" style="margin-right: 0" :to="{path: '/cart'}">
          <span style="color: #909399;font-weight: normal;">购物车</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="breaditem" style="margin-left: 0">
          <span style="color: #909399">失败列表</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
        <span class="span" style="color: #F56C6C"> （缺货图书已按系统库存加购）</span>
        <span class="span" style="color: #606266"> 全选复制可粘贴到excel表格</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="errors" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              style="width: 721px">
      <el-table-column label="书号" prop="isbn" width="110" :resizable="false"/>
      <el-table-column label="书名" prop="title" width="320" :resizable="false"/>
      <el-table-column label="定价" prop="price" width="75" :resizable="false"
                       :formatter="priceFormat"/>
      <el-table-column label="订数" prop="num" width="65" :resizable="false"/>
      <el-table-column label="备注" prop="comment" width="150" :resizable="false"/>
    </el-table>
  </div>
</template>

<script>
  export default {
    created () {
      try {
        this.errors = this.$route.params.errors
        this.total = this.$route.params.errors.length
      } catch (e) {
      }
    },
    data () {
      return {
        errors: [],
        total: 0
      }
    },
    methods: {
      priceFormat (row) {
        if (row.price !== '') {
          return parseFloat(row.price).toFixed(2)
        }
      }
    }
  }
</script>

<style scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
